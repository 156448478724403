<!-- eslint-disable no-unused-vars -->
<script setup>
import 'swiper/css';
import './styling/main.css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { $vfm } from 'vue-final-modal';
import renderComponentFromString from '@/components/render-component-from-string.vue';
import dynamic from '@/components/dynamic-component.vue';

import referrals from '@/components/referrals.vue';
import installModal from './components/install-modal.vue';
import cookieConsent from './components/cookie-consent.vue';
import ResponsiveImage from './components/responsive-image.vue';
import settings from '@/components/settings.vue';
import popup from '@/components/popup.vue';
import testimonial from '@/components/testimonial.vue';
import {
  props as propInterface,
  globalPresets
} from './functions/root-component-functions.js';
import ProfileMe from './layouts/ProfileMeClassic.vue';
// eslint-disable-next-line no-unused-vars
import Empty from './layouts/Empty.vue';
// eslint-disable-next-line no-unused-vars
import ProfileMeDesktop1 from './layouts/ProfileMeDesktop1.vue';
// eslint-disable-next-line no-unused-vars
import ProfileMeDesktop2 from './layouts/ProfileMeDesktop2.vue';
import {
  cookieExists,
  getCookieIfExistsElseMakeCookie,
  getOrCreateElement,
  getContactDetail,
  getKey,
  uniqid,
  getReferer
} from './scripts';
import {
  firebaseHandler,
  firebaseHandlerProxy,
  QUERY_FIELD_PRESETS,
  QUERY_VALUE_PRESETS,
  ANALYTICS_TYPE,
  ANALYTICS_ACTIONS
} from './scripts/firebaseHandler';
import pwaWrapper from '~/functions/wrappers/save-and-share-wrapper';
import bodyContentWrapper from '~/functions/wrappers/body-content-wrapper';
import titleInfoWrapper from '~/functions/wrappers/title-info-wrapper';
import contactButtonsWrapper from '~/functions/wrappers/contact-buttons-wrapper';
import leadFormWrapper from '~/functions/wrappers/lead-form-wrapper';
import profileImageWrapper from '~/functions/wrappers/profile-image-wrapper';
import footerWrapper from '~/functions/wrappers/footer-wrapper';
import {
  mergeProfiles,
  getPermissions
} from '~/functions/wrappers/merge-wrapper';
import {
  formatForSelfService,
  getIconType
} from '~/functions/wrappers/content-format-wrapper';
import firebaseConfig from './credentials/firebase-config.json';
import directives from '~/scripts/directives';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { toRef, onMounted, ref, computed, reactive, provide, watch } from 'vue';
import cssPlug from '~/functions/wrappers/css-plug-wrapper';
import { includes, pipe, pathOr, map, prop, reject, isNil, split } from 'ramda';
import { chain, assign } from 'lodash';
import { Toast } from 'toaster-js';
import 'toaster-js/default.css';

const props = defineProps(propInterface);

// Retrieving props
const {
  enableAnalytics,
  productionCollections,
  profileId,
  companyId,
  subdomain,
  alias
} = props;
const urlParams = new URLSearchParams(window.location.search);
const enablePwa = toRef(props, 'enablePwa');
const _leadFormData = toRef(props, 'leadFormData');
const unmergedDetails = toRef(props, 'details');
const loading = ref(true);
const showData = ref(urlParams.get('showData') == 'true');
const showReferrals = ref(false);
const showSettings = ref(false);
const cssClasses = ref([]);
const details = reactive({});
//Styling DOM Variables
const styleDOM = [];
const leadFormHandler = reactive(
  leadFormWrapper(
    [
      ..._leadFormData.value,
      ...(unmergedDetails.value?.leadForms || []),
      ...(unmergedDetails.value?.masterProfile?.leadForms || [])
    ],
    getContactDetail('email', 'email', unmergedDetails.contacts || [])
  )
);

const profileStyling = computed(
  () =>
    details?.profile_styling || {
      layout: 'desktop1',
      cssPlug: ''
    }
);
const updateStyling = () => {
  const { getStyle } = cssPlug(
    details?.masterProfile?.cssPlug ?? '',
    details.cssPlug ?? '',
    details.cssVariables,
    profileStyling.value,
    globalPresets,
    // Get all the keys of contacts
    pipe(pathOr([], ['contacts']), map(prop('key')), reject(isNil))(details)
  );
  const styling = getStyle();
  for (let index = 0; index < styling.cssPlugs.length; index++) {
    const cssPlug = styling.cssPlugs[index];

    if (!styleDOM[index])
      styleDOM[index] = getOrCreateElement('css-plug-' + index);
    if (styleDOM[index].innerHTML != cssPlug)
      styleDOM[index].innerHTML = cssPlug;
  }

  cssClasses.value = styling.classes;
};

const updateValues = () => {
  const detailsUnformatted = reactive(
    mergeProfiles(
      unmergedDetails.value,
      unmergedDetails.value.masterProfile,
      getPermissions(unmergedDetails.value),
      unmergedDetails.value.variableChangeRecords
    )
  );

  assign(
    details,
    formatForSelfService(
      detailsUnformatted,
      getIconType(detailsUnformatted),
      chain(detailsUnformatted)
        .get('profile_styling.layout')
        .defaultTo('desktop2')
        .includes(['desktop2', 'desktop1'])
        .value()
    )
  );

  leadFormHandler.leadFormData.leadFormData = [
    ..._leadFormData.value,
    ...(unmergedDetails.value?.leadForms || []),
    ...(unmergedDetails.value?.masterProfile?.leadForms || [])
  ];
  leadFormHandler.leadFormData.profileEmail = getContactDetail(
    'email',
    'email',
    details.contacts
  );
};

updateValues();
updateStyling();

const showTemplate = (template, type) => {
  const templates = {
    classic: ['tall', 'short', 'shortEmail'],
    desktop: ['desktop1', 'desktop2']
  };
  return includes(template, templates[type] || []);
};
const getTemplate = (template) => {
  const templates = {
    desktop1: ProfileMeDesktop1,
    desktop2: ProfileMeDesktop2
  };

  return templates[template] || Empty;
};
// Create a test URLSearchParams object
const searchParams = new URLSearchParams(window.location.search);

// Display the key/value pairs
const params = {};
for (const [key, value] of searchParams.entries()) {
  const k = QUERY_FIELD_PRESETS[key] || key;
  const v = QUERY_VALUE_PRESETS[value] || value;
  params[k] = v;
}

// Initializing Analytics
const referer = getReferer(params['query_referer']);
const isUnique = !cookieExists('_uviewer');
const browserId = getCookieIfExistsElseMakeCookie('_uviewer');
const sessionId = uniqid('session-', `-${new Date().getTime()}`);
const domain = location.hostname;

// Initializing the FireBase tools
if (enableAnalytics) firebase.initializeApp(firebaseConfig);
const fbHandler = enableAnalytics
  ? firebaseHandler(
      firebase.firestore(),
      productionCollections ? 'analytics_prod' : 'analytics',
      isUnique,
      browserId,
      sessionId,
      referer,
      profileId,
      companyId,
      params
    )
  : firebaseHandlerProxy(
      isUnique,
      browserId,
      sessionId,
      referer,
      profileId,
      companyId,
      params
    );

const captureEventTemp = fbHandler.getCaptureEventFunction({
  version: '3.0.0',
  alias,
  profileId,
  companyId,
  domain,
  subdomain
});

const captureEvent = ({ value, type, action, title }) => {
  captureEventTemp({
    value,
    type: ANALYTICS_TYPE[type],
    action: ANALYTICS_ACTIONS[action],
    title
  });
};

const submitFormToDB = fbHandler.submitFormToDB(
  productionCollections
    ? pathOr(
        'leads_prod',
        ['company', 'emailConfig', 'leadFormCollection'],
        details
      ) || 'leads_prod'
    : 'leads',
  !productionCollections,
  split(';', pathOr('', ['leadFormEmailCC'], details))
);

const pwaHandler = pwaWrapper(
  enablePwa,
  details,
  () =>
    captureEvent({
      value: window.location.href,
      action: 'click',
      type: 'pwa-install',
      title: ''
    }),
  () =>
    captureEvent({
      value: '',
      type: 'save-to-contacts',
      action: 'click',
      title: ''
    }),
  reactive
);
const bodyContentHandler = bodyContentWrapper(details);
const titleInfoHandler = titleInfoWrapper(details);
const contactButtonsHandler = contactButtonsWrapper(details);
const profileImageHandler = profileImageWrapper(details);
const footerHandler = footerWrapper(details);

// Change styling and trigger a page visit events
onMounted(() => {
  const head = document.head || document.getElementsByTagName('head')[0];

  for (let index = 0; index < styleDOM.length; index++) {
    const cssDOM = styleDOM[index];
    head.appendChild(cssDOM);
  }

  pwaHandler.handleSaveButtonPrompt();

  setTimeout(() => {
    loading.value = false;
  }, 1000);
  captureEvent({
    value: window.location.href,
    action: 'page_view',
    title: document.title,
    type: 'page_view',
    isUnique
  });
});
watch(
  unmergedDetails,
  () => {
    updateValues();
    updateStyling();
  },
  { deep: true }
);

const getSettingsConfig = () => {
  if (details.company) {
    return details.company.settingsConfig || undefined;
  }
  return {};
};

provide('submitFormToDB', submitFormToDB);
provide('captureEvent', captureEvent);
provide('notify', (msg) => {
  new Toast(msg, Toast.TYPE_DONE, 5000);
});
provide('vHref', directives.vHref(captureEvent));
provide('vEmbedDraggable', directives.vEmbedDraggable(captureEvent));
provide('vScrollOverEvent', directives.vScrollOverEvent(captureEvent));
provide('openModal', (value, type, name) => {
  $vfm.show(value);
  captureEvent({
    action: 'open',
    value: value,
    type: type,
    title: name
  });
});
provide('openReferrals', () => {
  showReferrals.value = true;
});
provide('openSettings', () => {
  showSettings.value = true;
});
provide('previewMode', props.previewMode);
</script>

<template>
  <div v-if="!showData" :id="'profile-id-' + details.id" :class="cssClasses">
    <profile-me
      v-if="showTemplate(profileStyling.layout, 'classic')"
      :details="details"
      :globalPresets="globalPresets.globalStylePresets"
      :leadFormData="leadFormHandler.leadFormData.leadFormData"
      :privacyUrl="privacyUrl"
      :supportUrl="supportUrl"
      :pwaHandler="pwaHandler"
    />
    <component
      v-if="showTemplate(profileStyling.layout, 'desktop')"
      :profile-details="details"
      :saveAndShareHandler="pwaHandler"
      :bodyContentHandler="bodyContentHandler"
      :titleInfoHandler="titleInfoHandler"
      :contactButtonsHandler="contactButtonsHandler"
      :profileImageHandler="profileImageHandler"
      :footerHandler="footerHandler"
      :leadFormHandler="leadFormHandler"
      :is="getTemplate(profileStyling.layout)"
    />
    <div v-else></div>
    <div
      v-if="loading"
      class="fixed left-0 right-0 top-0 bottom-0 h-full bg-white z-50"
    >
      <div class="flex flex-col justify-center items-center h-full">
        <div class="w-3/4 lg:w-1/4 flex justify-center animate-pulse">
          <responsive-image
            v-if="profileImageHandler.logo.url"
            class="loading-screen-image"
            :image="profileImageHandler.logo.url"
            :width="profileImageHandler.logo.width"
            :alt="profileImageHandler.logo.alt"
            :widthMobile="'200px'"
            :widthDesktop="'200px'"
          />
          <img
            v-else
            loading="lazy"
            src="./images/ProfileMeLogo.png"
            width="200px"
          />
        </div>
      </div>
    </div>
    <div v-else class="loading-done"></div>

    <!--#region Modals  -->
    <!-- GDPR COOKIE CONSENT -->
    <cookie-consent v-if="askCookieConsent"></cookie-consent>
    <!-- PWA INSTALL PROMPT -->
    <install-modal
      :profileName="titleInfoHandler.fullName"
      :profileImage="pwaHandler.profilePicture"
      :show="pwaHandler.showInstallPrompt"
      :isFirefox="pwaHandler.isFirefox"
      @onClose="pwaHandler.showInstallPrompt = false"
    />
    <template v-if="!showTemplate(profileStyling.layout, 'classic')">
      <popup
        v-for="(review, i) in details.testimonials"
        :key="i"
        :name="bodyContentHandler.getKeyFromTestimonial(review)"
        class="testimony-popup"
      >
        <div class="py-2">
          <testimonial :value="review" :limit="false" />
        </div>
      </popup>
      <popup
        v-for="(info, i) in bodyContentHandler.getNormalModals"
        :key="i"
        :name="getKey(info)"
        :class="info.key"
      >
        <template v-slot:default>
          <div class="p-4 rich-text ql-editor">
            <render-component-from-string
              v-if="leadFormHandler.isLeadForm(info.description)"
              :inData="leadFormHandler.leadFormData"
              :title="'Get in Touch / Request a Call Back'"
              :template="leadFormHandler.getForm(info.description)"
            />
            <dynamic
              v-else
              :template="leadFormHandler.buildElement(info.description)"
              :fontColor="profileStyling.fontColor || undefined"
              :headerColor="profileStyling.headerColor || undefined"
            ></dynamic>
          </div>
        </template>
      </popup>
      <settings
        :settingsConfig="getSettingsConfig()"
        :hubUrl="details?.company?.customHubUrl"
        :privacyUrl="privacyUrl"
        :supportUrl="supportUrl"
        :show="showSettings"
        @on-close="showSettings = false"
        @save-as-contact="pwaHandler.vcfDownloadUrl(details)"
        @add-to-home-screen="pwaHandler.triggerInstall(details)"
        @manage-cookie-consent="manageCookieConsent"
      />
      <referrals
        iconStyling="hidden"
        :message="titleInfoHandler.referralMessage"
        :email="titleInfoHandler.email"
        :number="titleInfoHandler.number"
        :full-name="titleInfoHandler.fullName"
        :show="showReferrals"
        :showSaveButton="pwaHandler.showSaveButton"
        @close="showReferrals = false"
        @saveClick="pwaHandler.handelSaveClick"
        @downloadContact="pwaHandler.vcfDownloadUrl"
      />
    </template>
    <!--#endregion -->
  </div>
  <pre v-else>
    {{ unmergedDetails }}
  </pre>
</template>
